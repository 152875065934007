var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('patient-filter',{attrs:{"patients":_vm.patients,"panel":true},on:{"filterChange":_vm.filterChange}}),_c('v-card',{staticClass:"v-card-profile pb-2 mt-3",attrs:{"elevation":"5"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bills,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.billLockFilter,"item-text":"text","item-value":"value","hide-details":"","dense":"","label":_vm.$t('bills.billLock')},model:{value:(_vm.filterIsLock),callback:function ($$v) {_vm.filterIsLock=$$v},expression:"filterIsLock"}})],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","rounded":""},on:{"click":function($event){return _vm.printItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-printer ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("print"))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","rounded":"","to":'/billDetails/' + item.guid,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{},[_vm._v("mdi-file-document-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("patients.viewBillDetails"))+" ")])])],1)]}},{key:"item.isLock",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","disabled":""},model:{value:(item.isLock),callback:function ($$v) {_vm.$set(item, "isLock", $$v)},expression:"item.isLock"}})]}},{key:"item.isPaid",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","disabled":""},model:{value:(item.isPaid),callback:function ($$v) {_vm.$set(item, "isPaid", $$v)},expression:"item.isPaid"}})]}},{key:"item.billDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.billDate,"YYYY/MM/DD hh:mm A"))+" ")]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.totalAmount,"0,0"))+" ")]}},{key:"item.totalPaid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.totalPaid,"0,0"))+" ")]}},{key:"item.totalDiscount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.totalDiscount,"0,0"))+" ")]}},{key:"item.remainingAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")((item.totalAmount - (item.totalPaid + item.totalDiscount)),"0,0"))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"blue--text text--darken",class:_vm.$vuetify.breakpoint.xsOnly
                ? ' v-data-table__mobile-table-row'
                : ''},[_c('td',{staticClass:"d-none d-sm-flex pa-3"},[_vm._v(" "+_vm._s(_vm.$t("total"))+" ")]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'd-none' : ''}),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'd-none' : ''}),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v(" "+_vm._s(_vm.$t("bills.totalAmountFooter"))+" ")]),_vm._v(" "+_vm._s(_vm._f("number")(_vm.sumTotals.sumTotalAmount,"0,0"))+" ")]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v(" "+_vm._s(_vm.$t("bills.totalPaidFooter"))+" ")]),_vm._v(" "+_vm._s(_vm._f("number")(_vm.sumTotals.sumTotalPaid,"0,0"))+" ")]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v(" "+_vm._s(_vm.$t("bills.totalDiscountFooter"))+" ")]),_vm._v(" "+_vm._s(_vm._f("number")(_vm.sumTotals.sumTotalDiscount,"0,0"))+" ")]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v(" "+_vm._s(_vm.$t("bills.totalRemainingFooter"))+" ")]),_vm._v(" "+_vm._s(_vm._f("number")(_vm.sumTotals.sumRemainingAmount,"0,0"))+" ")])])])],2),_c('v-divider'),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"align-self":"end"}},[_c('v-pagination',{staticClass:"mb-2 ",attrs:{"length":_vm.pageLength ? _vm.pageLength : 0},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{attrs:{"align-self":"center","cols":"2"}},[_c('v-select',{staticClass:"pa-1",attrs:{"dense":"","solo":"","label":_vm.$t('itemPerPage'),"items":_vm.itemPerPageSelect,"item-text":"text","item-value":"value","hide-details":""},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }