<template>
  <v-container fluid>
    <patient-filter
      :patients="patients"
      :panel="true"
      @filterChange="filterChange"
    ></patient-filter>

    <v-card class="v-card-profile pb-2 mt-3" elevation="5">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="bills"
          class=""
          :search="search"
          :loading="loading"
          :loading-text="$t('loading')"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    single-line
                    hide-details
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    :items="billLockFilter"
                    item-text="text"
                    item-value="value"
                    hide-details
                    dense
                    v-model="filterIsLock"
                    :label="$t('bills.billLock')"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="text-end">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    @click="printItem(item)"
                    ><v-icon> mdi-printer </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("print") }}
                </span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    rounded
                    class=""
                    v-bind="attrs"
                    v-on="on"
                    :to="'/billDetails/' + item.guid"
                    target="_blank"
                    ><v-icon class="">mdi-file-document-edit-outline </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("patients.viewBillDetails") }}
                </span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.isLock`]="{ item }">
            <v-checkbox dense v-model="item.isLock" disabled />
          </template>
          <template v-slot:[`item.isPaid`]="{ item }">
            <v-checkbox dense v-model="item.isPaid" disabled />
          </template>
          <template v-slot:[`item.billDate`]="{ item }">
            {{ item.billDate | moment("YYYY/MM/DD hh:mm A") }}
          </template>
          <template v-slot:[`item.totalAmount`]="{ item }">
            {{ item.totalAmount | number("0,0") }}
          </template>
          <template v-slot:[`item.totalPaid`]="{ item }">
            {{ item.totalPaid | number("0,0") }}
          </template>
          <template v-slot:[`item.totalDiscount`]="{ item }">
            {{ item.totalDiscount | number("0,0") }}
          </template>
          <template v-slot:[`item.remainingAmount`]="{ item }">
            {{
              (item.totalAmount - (item.totalPaid + item.totalDiscount))
                | number("0,0")
            }}
          </template>

          <template slot="body.append">
            <tr
              class="blue--text text--darken"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? ' v-data-table__mobile-table-row'
                  : ''
              "
            >
              <td class="d-none d-sm-flex pa-3">
                {{ $t("total") }}
              </td>
              <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
              <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
              <td
                :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
              >
                <span class="hidden-sm-and-up">
                  {{ $t("bills.totalAmountFooter") }}
                </span>
                {{ sumTotals.sumTotalAmount | number("0,0") }}
              </td>
              <td
                :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
              >
                <span class="hidden-sm-and-up">
                  {{ $t("bills.totalPaidFooter") }}
                </span>
                {{ sumTotals.sumTotalPaid | number("0,0") }}
              </td>
              <td
                :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
              >
                <span class="hidden-sm-and-up">
                  {{ $t("bills.totalDiscountFooter") }}
                </span>
                {{ sumTotals.sumTotalDiscount | number("0,0") }}
              </td>
              <td
                :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
              >
                <span class="hidden-sm-and-up">
                  {{ $t("bills.totalRemainingFooter") }}
                </span>
                {{ sumTotals.sumRemainingAmount | number("0,0") }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-divider />
        <v-row class="mt-2">
          <v-col cols="2"></v-col>

          <v-col align-self="end">
            <v-pagination
              class="mb-2 "
              v-model="page"
              :length="pageLength ? pageLength : 0"
            ></v-pagination>
          </v-col>
          <v-col align-self="center" cols="2">
            <v-select
              dense
              class="pa-1"
              solo
              :label="$t('itemPerPage')"
              :items="itemPerPageSelect"
              item-text="text"
              item-value="value"
              v-model="itemsPerPage"
              hide-details=""
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import PatientFilter from "../../components/PatientFilter.vue";

export default {
  components: { PatientFilter },

  data() {
    return {
      page: 1,
      itemsPerPage: 5,
      itemPerPageSelect: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
        { text: this.$t("all"), value: -1 },
      ],
      loading: true,
      show: false,
      type: "success",
      message: "Completed Successfully",
      search: "",
      bills: [],
      patients: [],
      headers: [
        { text: this.$t("id"), value: "id" },
        { text: this.$t("bills.billCode"), value: "billCode" },
        {
          text: this.$t("patients.patientName"),
          value: "patient.patientDisplayName",
        },
        { text: this.$t("procedures.totalAmount"), value: "totalAmount" },
        { text: this.$t("bills.paidAmounts"), value: "totalPaid" },
        { text: this.$t("bills.totalDiscount"), value: "totalDiscount" },
        { text: this.$t("bills.remainingAmount"), value: "remainingAmount" },
        { text: this.$t("date"), value: "billDate" },
        //{ text: this.$t("bills.isLock"), value: "isLock" },
        { text: this.$t("bills.isPaid"), value: "isPaid" },
        //{ text: this.$t("notes"), value: "notex" },
        { text: "", value: "actions" },
      ],
      editedItem: {
        voucherAmount: 0,
        note: "",
      },
      defaultItem: {
        voucherAmount: 0,
        note: "",
      },
      filterIsLock: 2,
      filterItem: {
        patientName: "",
        fromDate: this.dateNow,
        toDate: this.dateNow,
        billLock: 2,
      },

      billLockFilter: [
        { text: this.$t("bills.both"), value: 0 },
        { text: this.$t("bills.locked"), value: 1 },
        { text: this.$t("bills.notLocked"), value: 2 },
      ],
      rules: [
        (value) => !!value || "Required.",
        //(value) => (value && value.length <= 5) || "Max 5 characters",
      ],
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
    if (this.isInRole("28")) {
      axios.get("Patient").then((response) => {
        this.patients = response.data.data;
      });
    }
  },
  watch: {
    filterIsLock(val) {
      this.filterItem.billLock = val;
      this.refreshTable();
    },

    pageLength(val) {
      if (this.page > val) this.page = val;
    },
  },

  computed: {
    pageLength() {
      if (this.itemsPerPage == -1) {
        return 1;
      } else {
        var div = this.bills.length / this.itemsPerPage;
        return Number.isInteger(div) ? div : (div | 0) + 1;
      }
    },
    sumTotals() {
      var sumTotalAmount = this.bills.reduce(
        (a, b) => a + (b["totalAmount"] || 0),
        0
      );
      var sumTotalDiscount = this.bills.reduce(
        (a, b) => a + (b["totalDiscount"] || 0),
        0
      );
      var sumTotalPaid = this.bills.reduce(
        (a, b) => a + (b["totalPaid"] || 0),
        0
      );
      var sumRemainingAmount = sumTotalAmount - sumTotalDiscount - sumTotalPaid;
      return {
        sumTotalAmount,
        sumTotalDiscount,
        sumTotalPaid,
        sumRemainingAmount,
      };
    },
  },

  methods: {
    refreshTable() {
      axios
        .get("Bill/Get?filter=" + JSON.stringify(this.filterItem))
        .then((response) => {
          this.bills = response.data.data;
          console.log(this.bills);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    printItem(item) {
      axios({
        url: "Bill/Print?guid=" + item.guid,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    filterChange(filterItem) {
      this.filterItem = filterItem;
      this.filterItem.billLock = this.filterIsLock;
      this.refreshTable();
    },
  },
};
</script>

<style></style>
